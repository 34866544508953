<template>
  <div>
    <Navbar class="nav-z-idx"/>
    <div class="container-v3">
        <div class="center-content-v3">
            <div class="container-permission-auth">
                <span class="title-permission-auth">Two-Factor Authentication</span>
                <span class="my-3 sub-permission-auth">Enter verification code generated by your Google Authenticator app.</span>
                <!-- <pre>{{getEmailMFA}}{{getSecretMFA}}</pre> -->
                <form action="" @submit.prevent="submit">
                    <input 
                        type="number"
                        placeholder="6-digit code"
                        :class="invalidCode != null ? 'form-input-auth-code red-border-auth' : 'form-input-auth-code'"
                        v-model="number"
                    >
                </form>
                <span v-if="invalidCode != null" class="invalid-code-auth">Invalid code.</span>
                <button @click="submit()" :disabled="number == null || number == '' || invalidCode != null" class="my-3 btn-submit-auth-code"><i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>Sign in</button>
                <div class="link-backup-page">
                    <span @click="toBackup()" class="text-blue cursor-pointer">Unable to receive verification code?</span>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../../../components/nav-login-registerV3.vue";

export default {
    components: {
        Navbar
    },
    data() {
        return {
            number: null,
            maxNumberLength: 6,
            invalidCode: null
        }
    },
    computed: {
        getEmailMFA(){
            return this.$store.getters['mfa/emailAuth']
        },
        getSecretMFA(){
            return this.$store.getters['mfa/secretAuth']
        },
        loading(){
            return this.$store.getters['mfa/loading']
        }
    },
    watch: {
        number() {
            this.number = this.number.substring(0, this.maxNumberLength)
            if(this.number == null || this.number == ''){
                this.invalidCode = null
            }
        }
    },
    methods: {
        toBackup(){
            this.$router.push({name: 'MultiFactorBackup'})
        },
        submit(){
            var payload = {
                email: this.getEmailMFA,
                secret: this.getSecretMFA,
                token: this.number
            }
            this.$store.dispatch('mfa/verifyGoogleAuth', payload)
            .then((result)=>{
                this.$store.commit('state/SET_STATE', 'storage')
                this.$store.commit("workgroup/SET_WORKGROUP",null)
                this.$store.commit("storage/DELETE_BREADCUMB",[]);
                this.$store.dispatch("storage/listing")
                this.$router.push(
                    this.$route.query.redirectFrom || { name: "home" }
                )
            })
            .catch((err)=> {
                this.invalidCode = err
            })
        }
    }
}
</script>

<style>
.form-input-auth-code[type="number"]::-webkit-outer-spin-button,
.form-input-auth-code[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.form-input-auth-code[type="number"] {
    -moz-appearance: textfield;
}
.form-input-auth-code{
    border: 1px solid #E9EAEA;
    outline: none;
    border-radius: 4px;
    color: #262A2C;
    line-height: 20px;
    font-size: 16px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    letter-spacing: -0.02em;
    padding: 16px;
    width: 100%;
}
.form-input-auth-code::placeholder{
    color: #919394;
    line-height: 20px;
    font-size: 16px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    letter-spacing: -0.02em;
}
.btn-submit-auth-code{
    background: #00AAFF;
    border-radius: 4px;
    padding: 16px;
    color: #FFFFFF;
    line-height: 24px;
    font-size: 16px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    letter-spacing: -0.02em;
    border: none;
}
.btn-submit-auth-code:disabled{
    background: #E9EAEA;
    border-radius: 4px;
    padding: 16px;
    color: #919394;
    line-height: 24px;
    font-size: 16px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    letter-spacing: -0.02em;
    border: none;
}
.container-permission-auth{
    width: 426px;
    background-color: #FFFFFF;
    height: fit-content;
    padding: 32px;
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 16px;
}
.title-permission-auth{
    color: #262A2C;
    line-height: 30px;
    font-size: 24px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    letter-spacing: -0.04em;
}
.sub-permission-auth{
    color: #262A2C;
    line-height: 20px;
    font-size: 16px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    letter-spacing: -0.02em;
}
.link-backup-page{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    line-height: 24px;
    font-size: 16px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    letter-spacing: -0.02em;
}
.red-border-auth{
    border: 1px solid #D42020;
}
.invalid-code-auth{
    color: #D42020;
}
</style>